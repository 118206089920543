@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  font-family: 'Inter', sans-serif;
}

body::-webkit-scrollbar {
  display: none;
  font-family: 'Inter', sans-serif;
}

.custom-dropdown li div:active {
  background: transparent !important;
}

.dropdown-item:focus {
  background-color: transparent;
  outline: none;
}

body {
  /* background-color: #000; */
  color: aliceblue;
  box-sizing: border-box;

}

@media (max-width: 414px) {
  .text-sub-content {
    top: 8px;
    left: 110px;
    font-size: 10px;
  }
}
@media (max-width: 412px) {
  .text-sub-content {
    top: 5px;
    left: 110px;
    font-size: 9.5px;
  }
}
@media (max-width: 393px) {
  .text-sub-content {
    top: 4px;
    left: 105px;
    font-size: 9px;
  }
}
@media (max-width: 375px) {
  .text-sub-content {
    top: 3px;
    left: 98px;
    font-size: 8.4px;
  }
}
@media (max-width: 360px) {
  .text-sub-content {
    top: 3px;
    left: 92px;
    font-size: 8.4px;
  }
}
@media (max-width: 280px) {
  .text-sub-content {
    top: 0px;
    left: 70px;
    font-size: 7px;
  }
}

